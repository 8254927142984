import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import MentorSection from '../components/home/mentor_section'
import TestimonialSwitcher from '../components/home/testimonial_switcher'
import BannerBars from '../components/icons/banner_bars'
import "../components/home/home.css"

const HomeFrench = (props) => {
  const {strapiHomepage: data} = useStaticQuery(graphql`
    query {
      strapiHomepage {
        home_text
        meetOurMentorsText
        title
        bannerImage{
          localFile{
            publicURL
          }
        }
        howItWorks{
          stepOneTitle
          stepOneText
          stepOneIcon{
            localFile{
              publicURL
            }
          }
          stepTwoTitle
          stepTwoText
          stepTwoIcon{
            localFile{
              publicURL
            }
          }
          stepThreeTitle
          stepThreeText
          stepThreeIcon{
            localFile{
              publicURL
            }
          }
          stepFourTitle
          stepFourText
          stepFourIcon{
            localFile{
              publicURL
            }
          }
        }
      }
    }
  `)
  
  return (
  <Layout language="english" path={props.path}>

    <SEO language="english" title="Home" />
    <img className="home-mobile-banner" src={data.bannerImage.localFile.publicURL}/>
    <div className="home-top-section">
      <div style={{paddingTop:"20px"}}>
        <h1 className="page-title left">{data.title}</h1>
        <p>{data.home_text}</p>
        <Link
          to="/application-en"
          style={{padding: "0 30px"}} 
          className="common-button">Apply For Mentorship</Link>
      </div>
      <div style={{position:"relative", backgroundImage:`url("${data.bannerImage.localFile.publicURL}")`}}>
        <BannerBars className="banner-bars" />
      </div>
    </div>
    <div className="home-how-it-works">
      <h2 className="section-title">How it works</h2>
      <div>
        <div className="how-it-works-step">
          <div style={{backgroundColor:"#2fa843"}}>1</div>
          <img src={data.howItWorks.stepOneIcon.localFile.publicURL} />
          <p>{data.howItWorks.stepOneTitle}</p>
          <p>{data.howItWorks.stepOneText}</p>
        </div>
        <div className="how-it-works-step">
          <div style={{backgroundColor:"#dca71c"}}>2</div>
          <img style={{width:"45px"}} src={data.howItWorks.stepTwoIcon.localFile.publicURL} />
          <p>{data.howItWorks.stepTwoTitle}</p>
          <p>{data.howItWorks.stepTwoText}</p>
        </div>
        <div className="how-it-works-step">
          <div style={{backgroundColor:"#2fa843"}}>3</div>
          <img src={data.howItWorks.stepThreeIcon.localFile.publicURL} />
          <p>{data.howItWorks.stepThreeTitle}</p>
          <p>{data.howItWorks.stepThreeText}</p>
        </div>
        <div className="how-it-works-step">
          <div style={{backgroundColor:"#dca71c"}}>4</div>
          <img src={data.howItWorks.stepFourIcon.localFile.publicURL} />
          <p>{data.howItWorks.stepFourTitle}</p>
          <p>{data.howItWorks.stepFourText}</p>
        </div>
      </div>
      
        
      
    </div>
    <div className="meet-our-mentors">
      <h2 className="section-title">Meet our mentors</h2>
      <p>{data.meetOurMentorsText}</p>
      <MentorSection language="english" />
      <Link to="/mentors-en" style={{margin:"auto"}} className="common-button">View all mentors</Link>
    </div>
    <div style={{marginBottom:"40px"}}>
      <h2 className="section-title">testimonials</h2>
      <TestimonialSwitcher language="english" />
    </div>
  </Layout>
)}

export default HomeFrench